import { makeAutoObservable, runInAction } from 'mobx';

import { ISearchesApi } from 'src/app-features/searches-configuration/api/searches.api';
import {
    syncInMemorySearchAfterLinkingFilters as applyFiltersToUserSearch,
    removeEmptyCriteriaFromSearchFilterObject,
    syncInMemorySearchAfterUnlinkingFilter as unlinkFilterFromUserSearch,
} from 'src/app-features/searches-configuration/domain/features/shared';
import {
    ConfiguredSearch,
    DraftManipulatedFilters,
    InMemoryFilterCriteria,
    NarrativeConfigModel,
    SearchType,
} from 'src/app-features/searches-configuration/domain/models/configured-search';
import {
    DocumentGroupResults,
    GroupedResults,
} from 'src/app-features/searches-configuration/domain/models/document-group-results';
import { DocumentResults } from 'src/app-features/searches-configuration/domain/models/document.model';
import { FilterOperation } from 'src/app-features/searches-configuration/domain/models/filter-module-config-option';
import { SearchesToInbox } from 'src/app-features/searches-configuration/domain/models/leads-inbox';
import { IFilterModuleConfigStore } from 'src/app-features/searches-configuration/domain/stores/filter-module-configuration/store.interface';
import { hasAtLeastOneCriteriaAdded } from 'src/app-features/searches-configuration/domain/utils/filter-config-utils';
import {
    replaceDraftSearchByCreatedOneOnUserSearches,
    buildSearchFilterModulesPayload,
    getSelectedSearch,
    mapSearchFilterModuleToAppliedFilterModule,
} from 'src/app-features/searches-configuration/domain/utils/search-config-utils';
import { SubscriptionApi } from 'src/data/api/subscriptions/subscriptions.api';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { SubscriptionsStore } from 'src/data/stores/subscriptions/subscriptions.store';
import { UserStore } from 'src/data/stores/user/user.store';
import { FilterModuleType } from 'src/domain/models/filter-module/filter-module.model';
import { FolderType } from 'src/domain/models/folder/folder.model';
import {
    Language,
    languageToLocale,
} from 'src/domain/models/locale/locale.model';
import { Search } from 'src/domain/models/search/search.model';
import { SearchSubscriptionData } from 'src/domain/models/subscription/subscription.model';
import { sortNewArray } from 'src/utils/array.utils';
import { handleRequestAsync } from 'src/utils/handle-request.utils';

import { ISearchesConfigFeature } from './feature.interface';
import { ApolloClientService } from 'src/data/api/graphql/apollo-client';
import { GqlOperationUpdateSearchPrescreeningSettingsDocument } from 'src/data/api/graphql/br_search/generated';

const MAX_LOADED_LEADS_TO_INBOX = 10;

const DefaultStateForManipulatedFilters: DraftManipulatedFilters = {
    searchId: 0,
    linkedIds: [],
    unlinkedIds: [],
    initialLinkedIds: undefined,
};

const DefaultNarrativeConfig: NarrativeConfigModel = {
    searchNarrative: '',
    preScreeningGtpInboxLimit: 0,
    preScreeningGtpOn: false,
};

export class SearchesConfigFeature implements ISearchesConfigFeature {
    searchResult?: DocumentGroupResults;
    requestingFilterModules = false;
    requestingSearchResults = false;
    requestingMoreSearchResults = false;
    savingSearch = false;
    leadsInboxProgress = {
        inProgress: false,
        ids: [] as string[],
    };
    inMemorySearchFilterCriteria: InMemoryFilterCriteria = {
        filterId: 0,
        searchCriteria: {},
    };
    inMemoryNarrativeConfiguration: NarrativeConfigModel =
        DefaultNarrativeConfig;
    draftManipulatedFilters: DraftManipulatedFilters =
        DefaultStateForManipulatedFilters;
    currentPage = 0;
    hasUnsavedNarrativeConfigChanges = false;

    constructor(
        private userStore: UserStore,
        private filterModuleConfigStore: IFilterModuleConfigStore,
        private subscriptionsStore: SubscriptionsStore,
        private searchesApi: ISearchesApi,
        private subscriptionsApi: SubscriptionApi,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    get hasUnsavedAppliedFilters(): boolean {
        return (
            this.draftManipulatedFilters.unlinkedIds.length > 0 ||
            this.draftManipulatedFilters.linkedIds.length > 0
        );
    }

    get orderedSearches(): ConfiguredSearch[] {
        const searches: ConfiguredSearch[] = (
            this.userStore.user?.searches ?? []
        ).map(({ id, name, type, searchData, ownerId }) => {
            const searchSubscription = this.searchesSubscriptions.find(
                (s) => s.id === id,
            );

            return {
                id,
                name,
                type,
                ownerId,
                narrative: searchSubscription?.description ?? '',
                autoPrescreeningEnabled:
                    searchSubscription?.autoPrescreeningEnabled ?? false,
                autoPrescreeningInboxLimit:
                    searchSubscription?.autoPrescreeningInboxLimit ?? 0,
                filters: !this.filterModuleConfigStore.filterModulesMap
                    ? []
                    : sortNewArray(
                          searchData.filterModules.map((filter) =>
                              mapSearchFilterModuleToAppliedFilterModule(
                                  filter,
                                  this.filterModuleConfigStore
                                      .filterModulesMap!,
                              ),
                          ),
                      )((a, b) => a.name.localeCompare(b.name)),
            };
        });

        const sorted = sortNewArray(searches)(
            (a, b) =>
                a.type.localeCompare(b.type) || a.name.localeCompare(b.name),
        );

        return sorted;
    }

    get groupedSearchResults(): GroupedResults[] {
        const groupedResults: GroupedResults[] = [];
        const userLocale =
            languageToLocale[this.userStore.user?.language ?? Language.En];

        if (this.searchResult) {
            const orderedDescentResults = sortNewArray(
                this.searchResult.results,
            )((a, b) => {
                return b.updated?.getTime() - a.updated?.getTime();
            });

            orderedDescentResults.forEach((result) => {
                const month = result.updated.toLocaleString(userLocale, {
                    month: 'long',
                    year: 'numeric',
                });
                const existingGroup = groupedResults.find(
                    (group) => group.month === month,
                );

                if (existingGroup) {
                    existingGroup.total += 1;
                    existingGroup.orderedDescendentResults.push(result);
                } else {
                    groupedResults.push({
                        month,
                        total: 1,
                        orderedDescendentResults: [result],
                    });
                }
            });
        }

        return groupedResults;
    }

    get loadedLeadsNotYetInboxed(): SearchesToInbox[] {
        if (!this.searchResult?.results.length) {
            return [];
        }

        const leadsNotInboxedYet: SearchesToInbox[] = this.searchResult.results
            .filter(
                ({ folderType, preScreeningOutput }) =>
                    folderType === undefined && preScreeningOutput.accept,
            )
            .slice(0, MAX_LOADED_LEADS_TO_INBOX)
            .map(({ id, pipeDbCollection }) => ({
                id,
                collection: pipeDbCollection,
            }));

        return leadsNotInboxedYet;
    }

    get searchesSubscriptions(): SearchSubscriptionData[] {
        return this.subscriptionsStore.subscriptionsList;
    }

    setInMemorySearchFilterCriteria = (criteria: InMemoryFilterCriteria) => {
        this.inMemorySearchFilterCriteria = criteria;
    };

    setNarrativeConfiguration = (
        searchId: number,
        payload: Partial<NarrativeConfigModel>,
    ) => {
        this.inMemoryNarrativeConfiguration = {
            ...this.inMemoryNarrativeConfiguration,
            ...payload,
        };

        const selectedSearch = getSelectedSearch(
            searchId,
            this.orderedSearches,
        );

        if (selectedSearch) {
            const {
                searchNarrative,
                preScreeningGtpInboxLimit,
                preScreeningGtpOn,
            } = this.inMemoryNarrativeConfiguration;

            const hasUnsavedChanges =
                searchNarrative !== selectedSearch.narrative ||
                preScreeningGtpOn !== selectedSearch.autoPrescreeningEnabled ||
                preScreeningGtpInboxLimit !==
                    selectedSearch.autoPrescreeningInboxLimit;

            this.hasUnsavedNarrativeConfigChanges = hasUnsavedChanges;
        }
    };

    requestSearchSubscriptions = async () => {
        try {
            const subscriptions = await handleRequestAsync(
                this.subscriptionsApi.getUserSubscriptions,
                {},
            );

            if (subscriptions) {
                this.subscriptionsStore.setSubscriptions(subscriptions);
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                'request-search-subscriptions',
                error as Error,
            );
        }
    };

    requestSearchResults = async (
        searchId: number,
        isFirstSearch: boolean,
        cleanInMemoryCriteriaBeforeRequest = false,
    ) => {
        try {
            const selectedSearch = getSelectedSearch(
                searchId,
                this.orderedSearches,
            );
            if (!selectedSearch) {
                return;
            }

            runInAction(() => {
                if (cleanInMemoryCriteriaBeforeRequest) {
                    this.inMemorySearchFilterCriteria = {
                        filterId: 0,
                        searchCriteria: {},
                    };
                }
            });

            const hasInMemoryCriteria = hasAtLeastOneCriteriaAdded(
                this.inMemorySearchFilterCriteria.searchCriteria,
            );

            let mergedFilters = [...selectedSearch.filters];
            if (hasInMemoryCriteria) {
                if (this.inMemorySearchFilterCriteria.filterId > 0) {
                    mergedFilters = mergedFilters.filter(
                        (f) =>
                            f.id !== this.inMemorySearchFilterCriteria.filterId,
                    );
                }
                mergedFilters.push({
                    id: this.inMemorySearchFilterCriteria.filterId,
                    type: FilterModuleType.General,
                    name: 'In Memory Filter',
                    filterOperation: FilterOperation.and,
                    searchCriteria: removeEmptyCriteriaFromSearchFilterObject(
                        this.inMemorySearchFilterCriteria.searchCriteria,
                    ),
                });
            }

            const requests = [];
            const filterModules =
                buildSearchFilterModulesPayload(mergedFilters);

            if (!filterModules.length) {
                runInAction(() => {
                    this.searchResult = undefined;
                });
                return;
            }

            if (isFirstSearch) {
                runInAction(() => {
                    this.currentPage = 0;
                });

                requests.push(
                    this.searchesApi.searchCountByFilterModules({
                        searchInput: {
                            filterModules,
                            searchType: selectedSearch.type,
                        },
                        signal: new AbortController().signal,
                    }),
                );
            }

            requests.push(
                this.searchesApi.searchByFilterModulesWithNarrative({
                    searchInput: {
                        filterModules,
                        searchType: selectedSearch.type,
                        paginationConf: {
                            offset: this.currentPage * 10,
                        },
                    },
                    narrative:
                        this.inMemoryNarrativeConfiguration.searchNarrative,
                    signal: new AbortController().signal,
                }),
            );

            const requestFlagName = isFirstSearch
                ? 'requestingSearchResults'
                : 'requestingMoreSearchResults';

            runInAction(() => {
                this.onRequestLoading(true, requestFlagName);
            });

            const responses = await Promise.all(requests);

            runInAction(() => {
                this.currentPage += 1;

                if (isFirstSearch) {
                    this.searchResult = {
                        results: (responses[1] as DocumentResults)
                            .documentGroups,
                        total: (responses[1] as DocumentResults).totalCount,
                        totalInLast30Days: responses[0] as number,
                    };
                } else {
                    const newResults = responses[0] as DocumentResults;
                    this.searchResult?.results.push(
                        ...newResults.documentGroups,
                    );
                }

                this.onRequestLoading(false, requestFlagName);
            });
        } catch (error) {
            this.baseStore.onRequestFailed(
                'request-search-results',
                error as Error,
            );
        }
    };

    upsertSearch = async (
        payload: { id?: number; name: string },
        requestFilterModuleCallback?: () => Promise<void>,
    ) => {
        const { id, name } = payload;
        const selectedSearch = getSelectedSearch(id ?? 0, this.orderedSearches);

        if (!selectedSearch) {
            return;
        }

        try {
            this.onRequestLoading(true, 'savingSearch');
            const responseId = await handleRequestAsync(
                this.searchesApi.upsertSearch,
                {
                    id,
                    name,
                    type: selectedSearch.type,
                    filterModuleIds: selectedSearch.filters.map((f) => f.id),
                },
            );

            if (!id && responseId) {
                selectedSearch.id = responseId;
                selectedSearch.name = name;
                replaceDraftSearchByCreatedOneOnUserSearches(
                    selectedSearch,
                    this.userStore.user?.searches,
                );
                await this._commitDraftOfNarrativeConfiguration(selectedSearch);
            } else if (id) {
                const userSearch = this.userStore.user?.searches.find(
                    (s) => s.id === id,
                );
                if (userSearch) {
                    userSearch.name = name;
                }
            }

            await this.requestSearchSubscriptions();
            await requestFilterModuleCallback?.();

            this.onRequestLoading(false, 'savingSearch');

            return responseId;
        } catch (error) {
            this.baseStore.onRequestFailed(
                'upsert-search-name',
                error as Error,
            );
        }
    };

    deleteSearch = async (
        searchId: number,
        requestFilterModuleCallback?: () => Promise<void>,
    ) => {
        try {
            this.onRequestLoading(true, 'savingSearch');
            await handleRequestAsync(this.searchesApi.deleteSearch, {
                id: searchId,
            });
            this.userStore.user!.searches =
                this.userStore.user!.searches.filter(
                    (search) => search.id !== searchId,
                );
            await this.requestSearchSubscriptions();
            await requestFilterModuleCallback?.();
            this.onRequestLoading(false, 'savingSearch');
        } catch (error) {
            this.baseStore.onRequestFailed('delete-search', error as Error);
        }
    };

    applyFiltersToSearch = async (searchId: number, filterIds: number[]) => {
        const userSearch = this.userStore.user?.searches.find(
            (s) => s.id === searchId,
        );

        if (!this.draftManipulatedFilters.initialLinkedIds && userSearch) {
            this.draftManipulatedFilters.initialLinkedIds =
                userSearch.searchData.filterModules.map((f) => f.key) ?? [];
        }

        applyFiltersToUserSearch(filterIds, userSearch);
        this.linkSearchToFilterModules(searchId, filterIds);
        this.requestSearchResults(searchId, true);

        if (searchId > 0) {
            const reallyAddedFilterIds = filterIds.filter(
                (id) =>
                    !this.draftManipulatedFilters.initialLinkedIds?.includes(
                        id,
                    ),
            );

            this.draftManipulatedFilters = {
                ...this.draftManipulatedFilters,
                searchId,
                linkedIds: [
                    ...this.draftManipulatedFilters.linkedIds,
                    ...reallyAddedFilterIds,
                ],
                unlinkedIds: this.draftManipulatedFilters.unlinkedIds.filter(
                    (id) => !filterIds.includes(id),
                ),
            };
        }
    };

    removeFilterFromSearch = async (searchId: number, filterId: number) => {
        const userSearch = this.userStore.user?.searches.find(
            (s) => s.id === searchId,
        );

        if (!this.draftManipulatedFilters.initialLinkedIds && userSearch) {
            this.draftManipulatedFilters.initialLinkedIds =
                userSearch.searchData.filterModules.map((f) => f.key) ?? [];
        }

        unlinkFilterFromUserSearch(filterId, userSearch);
        this.unlinkSearchFromFilterModule(searchId, filterId);
        this.requestSearchResults(searchId, true);

        if (searchId > 0) {
            const filterReallyRemoved =
                !!this.draftManipulatedFilters.initialLinkedIds?.find(
                    (id) => id === filterId,
                );

            this.draftManipulatedFilters = {
                ...this.draftManipulatedFilters,
                searchId,
                unlinkedIds: filterReallyRemoved
                    ? [...this.draftManipulatedFilters.unlinkedIds, filterId]
                    : this.draftManipulatedFilters.unlinkedIds,
                linkedIds: this.draftManipulatedFilters.linkedIds.filter(
                    (id) => id !== filterId,
                ),
            };
        }
    };

    createDraftSearch = (type: SearchType) => {
        if (!this.userStore.user) {
            throw new Error('User not found in userStore');
        }

        const draftSearch: Search = {
            id: 0,
            name: '',
            type,
            ownerId: this.userStore.user.itemId,
            searchData: {
                filterModules: [],
            },
        };

        const existingDraftIndex = this.userStore.user.searches.findIndex(
            (s) => s.id === 0,
        );

        if (existingDraftIndex > -1) {
            this.userStore.user.searches[existingDraftIndex] = draftSearch;
        } else {
            this.userStore.user.searches.push(draftSearch);
        }
    };

    deleteDraftSearch = () => {
        if (!this.userStore.user) {
            throw new Error('User not found in userStore');
        }

        if (this.userStore.user.searches.findIndex((s) => s.id === 0) !== -1) {
            this.userStore.user.searches = this.userStore.user?.searches.filter(
                (s) => s.id !== 0,
            );
        }
    };

    commitSearchChanges = async (
        searchId: number,
        requestFilterModuleCallback?: () => Promise<void>,
    ) => {
        const selectedSearch = getSelectedSearch(
            searchId,
            this.orderedSearches,
        );

        if (!selectedSearch) {
            return;
        }

        try {
            this.onRequestLoading(true, 'savingSearch');

            //committing filter changes if any
            await this._commitDraftOfManipulatedFilters(
                selectedSearch,
                requestFilterModuleCallback,
            );

            //committing narrative changes if any
            await this._commitDraftOfNarrativeConfiguration(selectedSearch);

            //refreshing subscriptions
            await this.requestSearchSubscriptions();

            this.onRequestLoading(false, 'savingSearch');
        } catch (error) {
            this.baseStore.onRequestFailed(
                'commit-search-changes',
                error as Error,
            );
        }
    };

    private _commitDraftOfManipulatedFilters = async (
        selectedSearch: ConfiguredSearch,
        requestFilterModuleCallback?: () => Promise<void>,
    ) => {
        if (!this.hasUnsavedAppliedFilters) {
            return;
        }

        await handleRequestAsync(this.searchesApi.upsertSearch, {
            id: selectedSearch.id,
            name: selectedSearch.name,
            type: selectedSearch.type,
            filterModuleIds: selectedSearch.filters.map((f) => f.id),
        });
        await requestFilterModuleCallback?.();
        this.draftManipulatedFilters = DefaultStateForManipulatedFilters;
    };

    private _commitDraftOfNarrativeConfiguration = async (
        selectedSearch: ConfiguredSearch,
    ) => {
        if (!this.hasUnsavedNarrativeConfigChanges) {
            return;
        }

        await ApolloClientService.client.mutate({
            mutation: GqlOperationUpdateSearchPrescreeningSettingsDocument,
            variables: {
                searchId: selectedSearch.id,
                autoPrescreeningEnabled:
                    this.inMemoryNarrativeConfiguration.preScreeningGtpOn,
                autoPrescreeningInboxLimit:
                    this.inMemoryNarrativeConfiguration
                        .preScreeningGtpInboxLimit,
                description:
                    this.inMemoryNarrativeConfiguration.searchNarrative,
            },
        });

        this.inMemoryNarrativeConfiguration = DefaultNarrativeConfig;
        this.hasUnsavedNarrativeConfigChanges = false;
    };

    undoDraftOfManipulatedFilters = () => {
        if (!this.hasUnsavedAppliedFilters) {
            return;
        }

        if (
            this.draftManipulatedFilters.linkedIds.length > 0 &&
            this.draftManipulatedFilters.searchId > 0
        ) {
            const userSearch = this.userStore.user?.searches.find(
                (s) => s.id === this.draftManipulatedFilters.searchId,
            );

            this.draftManipulatedFilters.linkedIds.forEach((filterId) => {
                unlinkFilterFromUserSearch(filterId, userSearch);
                this.unlinkSearchFromFilterModule(
                    this.draftManipulatedFilters.searchId,
                    filterId,
                );
            });
        }

        if (
            this.draftManipulatedFilters.unlinkedIds.length > 0 &&
            this.draftManipulatedFilters.searchId > 0
        ) {
            const userSearch = this.userStore.user?.searches.find(
                (s) => s.id === this.draftManipulatedFilters.searchId,
            );

            applyFiltersToUserSearch(
                this.draftManipulatedFilters.unlinkedIds,
                userSearch,
            );
            this.linkSearchToFilterModules(
                this.draftManipulatedFilters.searchId,
                this.draftManipulatedFilters.unlinkedIds,
            );
        }

        this.draftManipulatedFilters = DefaultStateForManipulatedFilters;
    };

    inboxSearches = async (
        leadsToInbox: SearchesToInbox[],
        searchId: number,
    ) => {
        const leadsIds = leadsToInbox.map(({ id }) => id);

        try {
            await handleRequestAsync(
                this.searchesApi.inboxSearches,
                {
                    docGroupsInfo: leadsToInbox.map(({ id, collection }) => ({
                        id,
                        collection,
                    })),
                    searchId,
                },
                (loading) => this.onInboxingLeads(leadsIds, loading),
            );

            runInAction(() => {
                this.onInboxingLeadsSuccess(leadsIds);
            });
        } catch (error) {
            this.baseStore.onRequestFailed('inbox-leads', error as Error);
        }
    };

    onRequestLoading = (
        loading: boolean,
        flag:
            | 'requestingSearchResults'
            | 'requestingMoreSearchResults'
            | 'savingSearch',
    ) => {
        this[flag] = loading;
    };

    onInboxingLeads = (ids: string[], inboxing: boolean) => {
        this.leadsInboxProgress.inProgress = inboxing;
        if (inboxing) {
            this.leadsInboxProgress.ids.push(...ids);
        } else {
            this.leadsInboxProgress.ids = [];
        }
    };

    onInboxingLeadsSuccess = (leadIds: string[]) => {
        leadIds.forEach((leadId) => {
            const lead = this.searchResult?.results.find(
                ({ id }) => id === leadId,
            );
            if (lead) {
                lead.folderType = FolderType.inbox;
            }
        });
    };

    /**
     * For each filter, updates its appliedToSearchIds property with the search id
     * @param searchId Id of the search linked
     * @param filterIds List of filter ids linked to the search
     */
    linkSearchToFilterModules = (searchId: number, filterIds: number[]) => {
        const filterModules = Array.from(
            this.filterModuleConfigStore.filterModulesMap?.values() ?? [],
        ).filter((f) => filterIds.includes(f.id));

        filterModules.forEach((filterModule) => {
            filterModule.linkedSearchIds.push(searchId);
        });
    };

    /**
     * For a unlinked filter, removes the search id from its appliedToSearchIds property
     * @param searchId Id of the search unlinked
     * @param filterId Id of the filter unlinked
     */
    unlinkSearchFromFilterModule = (searchId: number, filterId: number) => {
        const filterModule = Array.from(
            this.filterModuleConfigStore.filterModulesMap?.values() ?? [],
        ).find((f) => f.id === filterId);

        if (filterModule) {
            filterModule.linkedSearchIds = filterModule.linkedSearchIds.filter(
                (id) => id !== searchId,
            );
        }
    };
}
