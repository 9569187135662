import {
    UserAchievementName,
    UserAchievementState,
    UserAchievementsFragment,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { Colleague } from 'src/domain/models/colleague/colleague.model';

export enum AchievementCategory {
    WarriorsPath = 'warriors_path',
    CenturionsArmy = 'centurions_army',
}

export interface AchievementDetail {
    name: UserAchievementName;
    state: UserAchievementState | null;
    updatedDate: Date | null;
    category: AchievementCategory;
    shouldShowConfirmation: boolean;
}

export interface AchievementCategoryList {
    category: AchievementCategory;
    achievements: AchievementDetail[];
}

export type AchievementMap = {
    [key in UserAchievementName]?: AchievementDetail;
};

export const AchievementNameMap: Record<
    keyof Omit<UserAchievementsFragment, '__typename'>,
    UserAchievementName
> = {
    addContactsEmailOrPhone: UserAchievementName.AddContactsEmailOrPhone,
    askArticleWizard: UserAchievementName.AskArticleWizard,
    assignContactDealRole: UserAchievementName.AssignContactDealRole,
    bookedFirstMeeting: UserAchievementName.BookedFirstMeeting,
    createYourFirstContact: UserAchievementName.CreateYourFirstContact,
    createYourFirstDeal: UserAchievementName.CreateYourFirstDeal,
    disqualifyADeal: UserAchievementName.DisqualifyADeal,
    outreachByEmail: UserAchievementName.OutreachByEmail,
    outreachByPhone: UserAchievementName.OutreachByPhone,
    pauseADeal: UserAchievementName.PauseADeal,
    planANextStep: UserAchievementName.PlanANextStep,
    resolveANextStep: UserAchievementName.ResolveANextStep,
};

export interface ColleagueAchievementsDetails {
    userId: number;
    defaultPipelineId: string;
    optInAchievements: boolean;
    achievements: AchievementMap;
}

export interface ColleagueAchievements {
    colleague: Colleague;
    defaultPipelineId: string;
    optInAchievements: boolean;
    achievements: AchievementCategoryList[];
}

export interface PipelineAchievements {
    pipelineName: string;
    pipelineId: string;
    achievements: ColleagueAchievements[];
}
