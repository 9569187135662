import { TFunction, Trans } from 'react-i18next';

import { AchievementBadgeComponent } from 'src/app-features/achievements/presentation/achievements-section/component/achievement-badge/achievement-badge.component';
import {
    AchievementDetailsPartFragment,
    UserAchievementName,
    UserAchievementsFragment,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';

import {
    AchievementCategory,
    AchievementCategoryList,
    AchievementDetail,
    AchievementMap,
    AchievementNameMap,
} from './model/achievement.model';

export const getToasterText = (
    key: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    t: TFunction<'translation', undefined>,
) => (
    <Trans
        t={t}
        i18nKey={key}
        values={values}
        shouldUnescape
        components={{ bold: <strong /> }}
    />
);

export const getAchievementBadgeIcon = (achievement: AchievementDetail) => (
    <AchievementBadgeComponent achievement={achievement} />
);

const SortedAchievements: Record<AchievementCategory, UserAchievementName[]> = {
    [AchievementCategory.WarriorsPath]: [
        UserAchievementName.CreateYourFirstDeal,
        UserAchievementName.CreateYourFirstContact,
        UserAchievementName.AddContactsEmailOrPhone,
        UserAchievementName.OutreachByPhone,
        UserAchievementName.OutreachByEmail,
        UserAchievementName.BookedFirstMeeting,
    ],
    [AchievementCategory.CenturionsArmy]: [
        UserAchievementName.PlanANextStep,
        UserAchievementName.ResolveANextStep,
        UserAchievementName.AskArticleWizard,
        UserAchievementName.DisqualifyADeal,
        UserAchievementName.AssignContactDealRole,
        UserAchievementName.PauseADeal,
    ],
};

const getAchievementCategory = (
    name: UserAchievementName,
): AchievementCategory => {
    if (SortedAchievements[AchievementCategory.WarriorsPath].includes(name)) {
        return AchievementCategory.WarriorsPath;
    }
    return AchievementCategory.CenturionsArmy;
};

export const getSortedCategoryList = (
    achievementMap: AchievementMap,
    isActive?: (name: UserAchievementName) => boolean,
): AchievementCategoryList[] => {
    return Object.values(AchievementCategory).map((category) => {
        const achievementNames = SortedAchievements[category];
        return {
            category,
            achievements: achievementNames
                .map((name) => achievementMap[name])
                .filter(
                    (a): a is AchievementDetail =>
                        !!a && (isActive ? isActive(a.name) : true),
                ),
        };
    });
};

const createAchievement = (
    name: UserAchievementName,
    achievement: AchievementDetailsPartFragment,
): AchievementDetail => {
    return {
        name,
        state: achievement.achievementState ?? null,
        updatedDate: achievement.achievementStateUpdateDate
            ? new Date(achievement.achievementStateUpdateDate)
            : null,
        category: getAchievementCategory(name),
        shouldShowConfirmation: false,
    };
};

export const createAchievementArray = (
    achievements: UserAchievementsFragment,
): AchievementDetail[] => {
    delete achievements.__typename;
    return Object.keys(achievements)
        .filter((key) => key !== '__typename')
        .map((key) => {
            const propKey = key as unknown as keyof Omit<
                UserAchievementsFragment,
                '__typename'
            >;
            const achievement = achievements[propKey];
            if (achievement) {
                const name = AchievementNameMap[propKey];
                return createAchievement(name, achievement);
            }
            return undefined;
        })
        .filter((a): a is AchievementDetail => !!a);
};

export const createAchievementMap = (
    achievements: UserAchievementsFragment,
): AchievementMap => {
    const achievementMap: AchievementMap = {};
    delete achievements.__typename;
    Object.keys(achievements).forEach((key) => {
        const propKey = key as unknown as keyof Omit<
            UserAchievementsFragment,
            '__typename'
        >;
        const achievement = achievements[propKey];
        if (achievement) {
            const name = AchievementNameMap[propKey];
            const achievementObj = createAchievement(name, achievement);
            achievementMap[name] = achievementObj;
        }
    });
    return achievementMap;
};
